import { HodorSdkConfig } from '@canalplus/sdk-hodor';
import { useInfiniteQuery, UseInfiniteQueryOptions, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRoutingContext } from '../../../components/Page/RoutingContext';
import { DEFAULT_CACHE_TIME, DEFAULT_STALE_TIME } from '../../../constants/reactQuery';
import { TEMPLATES_WITH_NO_CACHE } from '../../../services/constants';
import type { FetchDetails, FormatterProps } from '../../../services/types';
import { universalService } from '../../../services/UniversalService';
import { hodorSdkConfigSelector, platformSelector } from '../../../store/slices/application-selectors';
import { useAlternateLinks } from '../useAlternateLinks';
import { useAppDispatch } from '../useAppDispatch';
import { useGenerateQueryKey } from '../useGenerateQueryKey';
import { useMetas } from '../useMetas';
import { useTracking } from '../useTracking';
import { InfiniteQueryHandlerResult, UseInfiniteQueryHandlerResult } from './types';

/**
 * ## useInfiniteQueryTemplate
 * Custom hook to handle formatters and tracking without code duplication
 *
 * @param queryKey Key of the request, needs to be unique and static to be useful for caching
 * @param fetchDetails Details of Hodor requests
 * @param reactQueryOptions Options for React Query base hook
 * @param formatterProps Props passed to formatter if needed
 * @param queryStrings Add queryStrings to nextPageUrl
 *
 * @example useInfiniteQueryTemplate<ContentRowLiveData>(formattedUrl, { template: 'ContentRowLive' }, reactQueryOptions, []);
 */
function useInfiniteQueryTemplate<T extends InfiniteQueryHandlerResult>(
  url: string | undefined,
  fetchDetails: FetchDetails,
  reactQueryOptions: Omit<UseInfiniteQueryOptions<T, Error>, 'queryKey' | 'initialPageParam' | 'select'>,
  formatterProps?: FormatterProps
): UseInfiniteQueryHandlerResult<T> {
  const { sendTracking } = useTracking();
  const queryClient = useQueryClient();
  const actualQueryKey = useGenerateQueryKey(url, fetchDetails);
  const routingContext = useRoutingContext();

  const appDispatch = useAppDispatch();
  const platform = useSelector(platformSelector);
  const hodorSdkConfig = useSelector(hodorSdkConfigSelector);

  const { disableMetaUpdate = false, enableAlternateLinksUpdate = false, noTracking } = fetchDetails;
  const { enabled, getNextPageParam, ...restReactQueryOptions } = reactQueryOptions || {};

  const onError = useCallback(() => {
    // Cache invalidation when Hodor returns an error template
    if (queryClient && actualQueryKey) {
      queryClient.removeQueries({ queryKey: actualQueryKey, exact: true });
    }
  }, [queryClient, actualQueryKey]);

  /**
   * Because the client side hodorSdkConfig is defined during the Hodor cinematic,\
   * just after the first rendering of the page, we need to check if it's defined\
   * to enable the hook.
   */
  const isHodorSdkConfigDefined = Boolean(hodorSdkConfig);

  const isCacheDisabled =
    !url || TEMPLATES_WITH_NO_CACHE.includes(fetchDetails.template) || Boolean(fetchDetails.options?.isPerso);

  /**
   * useQuery base hook
   * We pass all the parameters to the formatter with this syntax
   * Return the given type as T as UseQueryResult<T, Error>
   */
  /**
   * ATTENTION: Do not destructuring completely the object given by useQuery with "...rest" here
   * because since react-query v4 the query is "tracked" by default with properties used by the component to optimize render.
   * You have to destructure only the properties needed here or in the component
   */

  const useQueryData = useInfiniteQuery({
    queryKey: actualQueryKey, // eslint-disable-line @tanstack/query/exhaustive-deps

    queryFn: ({ pageParam }) =>
      universalService<T>({
        url: pageParam ? String(pageParam) : url,

        fetchDetails: {
          ...fetchDetails,

          // We can safely cast hodorSdkConfig to HodorSdkConfig because
          // we conditionally enable the hook only if it's defined
          ...((isHodorSdkConfigDefined && { hodorSdkConfig }) as { hodorSdkConfig: HodorSdkConfig }),
        },
        formatterProps: { ...formatterProps, dispatch: appDispatch, platform },
        onError,
        routingContext,
      }),
    getNextPageParam: (...nextPageParams) => {
      if (!isHodorSdkConfigDefined) {
        return;
      }
      return getNextPageParam(...nextPageParams);
    },

    initialPageParam: url,

    // If url isn't defined we're enabling "noCache" mode
    // Else we pass the default cache time
    gcTime: !isCacheDisabled ? DEFAULT_CACHE_TIME : 0,

    staleTime: !isCacheDisabled ? DEFAULT_STALE_TIME : 0,
    enabled: isHodorSdkConfigDefined && enabled,
    ...restReactQueryOptions,
  });

  const { data } = useQueryData;
  const [firstPage] = data?.pages || [];
  const { tracking, context } = firstPage || {};

  // To ensure sending tracking only once or when only tracking changes
  useEffect(() => {
    if (tracking && !noTracking) {
      sendTracking({ tracking, options: { ...(context && { trackingContext: context }) } });
    }
  }, [tracking, noTracking]); // eslint-disable-line react-hooks/exhaustive-deps

  useMetas(disableMetaUpdate, data);
  useAlternateLinks(enableAlternateLinksUpdate, data);

  return [useQueryData, actualQueryKey];
}

export default useInfiniteQueryTemplate;
